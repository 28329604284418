<template>
  <div class="align-center page">
    <!-- 搜索栏 -->
    <div class="search-box">
      <!-- <form action="/" class="flex align-center"> -->
      <form action="/" class="align-center">
        <!-- 
        <van-field v-model="keyword" type="tel" left-icon="search" maxlength="11" placeholder="请输入手机号" class="flex align-center search-input" />
        <van-button type="primary" block color="#048DAD" class="radius letter-spacing" @touchstart="onSearch">拍 摄</van-button>
        -->
        <van-search v-model.trim="keyword" type="tel" show-action :clearable="false" placeholder="请输入手机号" class="flex align-center search-input1" @search="onSearch">
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
      </form>
    </div>
    
    <!-- 输入栏 -->
    <div class="container">
      <div class="title font20">现场查验</div>
      
      <div class="content">
        <div class="flex row-item">
          <div class="label-item">姓名</div>
          <van-field v-model="info.id_card_name" placeholder="" readonly class="flex radius align-center input-item" />
        </div>
        <div class="flex row-item">
          <div class="label-item">手机</div>
          <van-field v-model="info.phone" placeholder="" readonly class="flex radius align-center input-item" />
        </div>
        <div class="flex row-item">
          <div class="label-item">客户<br/>类型</div>
          <van-field v-model="info.name" placeholder="" readonly class="flex radius align-center input-item" />
        </div>
        <div class="flex row-item">
          <div class="label-item">门票<br/>类型</div>
          <van-field v-model="info.ticket_type_txt" readonly class="flex radius align-center input-item" />
        </div>
        <div class="flex row-item">
          <div class="label-item">首次<br/>入场</div>
          <van-field v-model="info.entrance_time" readonly class="flex radius align-center input-item" />
        </div>
        <div class="flex row-item">
          <div class="label-item">图像</div>
          <div class="flex justify-center align-center radius avatar">
            <img v-if="info.face_path" :src="info.face_path" style="width: auto; height: 180px;" >
            <van-icon v-else name="photo" color="#dddddd" size="2rem" />
          </div>
        </div>
      </div>
      <div class="btn-box">
        <van-button type="primary" block color="#048DAD" :disabled="!info.id" class="radius letter-spacing next-btn">拍 摄</van-button>
        <!-- 透明的文件选择器，遮罩在“拍摄”按钮上面 -->
        <input v-show="info.id" type="file" id="fileElem" accept="image/*" ref="imgFile" class="file-elem" @change="chooseImg" />
      </div>
    </div>
    
    <!-- 页脚 -->
    <div class="footer">
    	<bottom :mode="0" :level="0"></bottom>
    </div>
    
    
  </div>
</template>

<script>
  import bottom from '@/view/process/components/bottom.vue';
  import { Field, Icon, Button, Search } from 'vant';
  import { debounce, parseTime } from '@/utils/utils.js';
  import {getCheckInfo, updateCheckImg} from '@/api/index.js';
  export default {
    components: {
      [Field.name]: Field,
      [Button.name]: Button,
      [Icon.name]: Icon,
      [Search.name]: Search,
      bottom,
    },
    data() {
      return {
        keyword: '', // 15907658577
        info: {
          id: '',
          id_card_name: '',
          phone: '',
          name: '',
          ticket_type_txt: '',
          entrance_time: '',
          face_path: '',
        },
        tempImg: '',
        uid: '',
      }
    },
    created() {
      document.title = this.$router.history.current.meta.title;
      this.uid = this.$route.query.uid || ''; // 登录后的凭证、或为小程序<web-view>传入的参数
      !this.uid && this.$router.push('/check'); // uid都为空时，进入核验登录页面
    },
    methods: {
      // 节流查询
      // onSearch: debounce('getInfo', 500),
      onSearch(val){
        if (!val) return;
          
        this.getInfo()
      },
      clear() {
        this.keyword = '';
      },
      getInfo() {
        if(this.keyword) {
          let params = {
            phone: this.keyword,
            unionid: this.uid,
          };
          getCheckInfo(params).then(res => {
            // console.log(res);
            res.data.entrance_time = res.data.entrance_time ? parseTime(res.data.entrance_time) : '';
            // 门票类型
            res.data.ticket_type_txt = ['预登记用户', '单日门票', '俩日联票', '四日联票'][res.data.pay_ticket_type || 0];
            
            this.info = res.data;
          })
        }
      },
      toPhoto() {
        if (!this.info.id){
          this.$toast('请先筛选出用户后再拍摄');
          return;
        }
        
        setTimeout(() => {
        	//触发点击事件
          // this.$refs.imgFile.click(); // 部分iphone不兼容
        	document.querySelector('#fileElem').click();
        }, 100)
      },
      //选择图片/拍摄照片
      chooseImg(e) {
      	let files = e.target.files || e.dataTransfer.files;
      	if (!files.length) return;
      	this.imgPreview(files[0]);
      },
      // 预览图片
      imgPreview(file) {
      	let that = this;
      
      	if (file) {
      		let reader = new FileReader();
      		let image = new Image();
      		
      		// (File 或 Blob)读取完成时
      		reader.onload = function(event) {
      			
      			// 图片加载完毕时
      			image.onload = function() {
      				
      				// 设置默认宽高: 宽高比例 === 图片宽高比例
      				let width = 750,
      						height = width * (image.height / image.width);
      				
      				// 创建画布
      				let canvas = document.createElement("canvas");
      				// 设置画布宽高
      				canvas.width = width;
      				canvas.height = height;
      
      				let ctx = canvas.getContext('2d');
      				ctx.drawImage(image, 0, 0, width, height); // 绘图
      				
      				// 图片转base64, 参数: 图片类型(file.type), 图片质量
      				let dataURI = canvas.toDataURL("image/jpeg", 0.9);
      				that.tempImg = dataURI; // 预览本地选中的图片
              console.log('tempImg: ', that.tempImg);
      				that.uploadFile(dataURI); // 上传图片
      			}
      			
      			// 开始加载图片
      			image.src = event.target.result; // 将获取到的base64字符串赋值到图片路径
      		}
      	
      		// 进行(File 或 Blob)读取
      		reader.readAsDataURL(file);
      	}
      },
      // 上传图片
      uploadFile(dataURI) {
      
      	this.$toast.loading({
      		forbidClick: true,
      		duration: 0,
      	});
      	
      	let params = {
      	  userId: this.info.id,
          unionid: this.uid,
      	  base64: dataURI.split(',')[1]
      	}
      	updateCheckImg(params).then(res => {
          if (res.code == 200) {
            this.$toast('上传成功!');
            // this.info.face_path = this.tempImg; // 图片替换
            this.$set(this.info, 'face_path', this.tempImg)
            console.log('info.face_path: ', this.info.face_path);
          } else{
            this.tempImg = ''
          }
      	});
      },
      
    },
  }
</script>

<style lang="less" scoped>

  .search-box {
    width: 90%;
    // height: 36px;
    height: 34px;
    margin: 2.5vh auto;
    // padding: 0 20Px 0 10Px;
    border-radius: 100Px;
    border: 1Px solid #048DAD;
    box-sizing: border-box;
    
    .search-input {
      height: 34px;
      border-radius: 100Px;
      background-color: transparent;
    }
    
    .search-input1 {
      height: 32px;
      background-color: transparent;
      padding: 0;
      
      .van-search__content {
        background-color: transparent;
      }
      .van-search__action {
        margin: 0 10px 0 0;
        padding: 0 10px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        border-radius: 6px;
        color: #fff;
        background-color: #048DAD;
      }
    }
  }
  .container {
    width: 80%;
    
    .title {
      color: #048DAD;
      font-weight: bold;
      text-align: center;
      margin-bottom: 2vh;
    }
    
    .content {
      margin-bottom: 4vh;
      
      .row-item {
        margin-bottom: 2vh;
        
        .label-item {
          width: 60px;
          height: 36px;
          font-size: 14px;
          display: flex;
          align-items: center;
          letter-spacing: 1Px;
        }
        .input-item {
          height: 36px;
          padding-top: 0;
          padding-bottom: 0;
          font-size: 14px;
        }
      }
      
      .avatar {
        width: 100%;
        height: 160px;
        border: 1Px dashed #ddd;
        background-color: #fff;
      }
    }
    
    .btn-box {
      position: relative;
      width: 240px;
      height: 40px;
      margin: 0 auto 1vh;
      overflow: hidden;
      
      .next-btn {
        width: 240px;
        height: 40px;
        font-size: 16px;
      }
      .file-elem {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        display: block;
        width: 240px;
        height: 40px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
  
</style>
